<template>
  <div
    :data-tags="news.source"
    :data-category="news.category.toLowerCase()"
    :class="news.category.toLowerCase()"
  >
    <div class="uk-card uk-card-small uk-card-default">
      <div class="uk-card-header">
        <div class="uk-grid uk-grid-small uk-text-small" data-uk-grid>
          <div class="uk-width-expand">
            <span class="cat-txt">{{ news.category }}</span>
          </div>
          <div class="uk-width-auto uk-text-right uk-text-muted">
            <span
              data-uk-icon="icon:clock; ratio: 0.7"
              class="uk-margin-small-right"
            ></span
            >{{ dateinhum(news.pub_date) }}.
          </div>
        </div>
      </div>
      <div class="uk-card-media">
        <div
          class="uk-inline-clip uk-transition-toggle"
          tabindex="0"
          v-if="news.image"
        >
          <img
            class="lazy"
            v-bind:data-src="news.image"
            data-width="400"
            data-height="300"
            data-uk-img
            alt=""
            src="img/transp.gif"
          />
          <div
            class="
              uk-transition-slide-bottom
              uk-position-bottom
              uk-overlay
              uk-overlay-primary
            "
          >
            Image Courtesy : {{ news.source }}
          </div>
        </div>
      </div>

      <div class="uk-card-body">
        <a v-bind:href="news.link" target="_blank">
          <h6
            class="
              uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold
            "
          >
            {{ news.title }}
          </h6>
        </a>
        <p class="uk-text-small uk-text-muted">{{ news.description }}</p>
      </div>

      <div class="uk-card-footer">
        <div
          class="uk-grid uk-grid-small uk-grid-divider uk-flex uk-flex-middle"
          data-uk-grid
        >
          <div class="uk-width-expand uk-text-small">
            {{ news.source }}
          </div>
          <div class="uk-width-auto uk-text-right">
            <a
              v-bind:href="news.link"
              data-uk-tooltip="title: Facebook"
              class="uk-icon-link"
              data-uk-icon="icon:facebook; ratio: 0.8"
            ></a>
            <a
              v-bind:href="news.link"
              data-uk-tooltip="title: Twitter"
              class="uk-icon-link"
              data-uk-icon="icon:twitter; ratio: 0.8"
            ></a>
            <a
              v-bind:href="news.link"
              data-uk-tooltip="title: Linkedin"
              class="uk-icon-link"
              data-uk-icon="icon:linkedin; ratio: 0.8"
            ></a>
            <a
              v-bind:href="news.link"
              data-uk-tooltip="title: WhatsApp"
              class="uk-icon-link"
              data-uk-icon="icon:whatsapp; ratio: 0.8"
            ></a>
          </div>
          <div class="uk-width-auto uk-text-right">
            <a
              data-uk-tooltip="title: Click to Copy Link"
              class="uk-icon-link"
              data-uk-icon="icon:link; ratio: 0.8"
              v-bind:data-clipboard-text="news.link"
              id="link"
            ></a>
            <a
              data-uk-tooltip="title: Drag this card"
              href="#"
              class="uk-icon-link drag-icon"
              data-uk-icon="icon:move; ratio: 0.8"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "News",
  props: {
    news: {},
  },
  methods: {
    dateinhum: function (dates) {
      return moment(dates).fromNow(true);
    },
  },
};
</script>