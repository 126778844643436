<template>
	<div v-if="searches" >
		<span v-if="news.length > 0">Showing results for <strong>{{ searches }}</strong>. Still not found what are you looking for? <a v-bind:href="searchlink">Click here</a> to search in older news.</span>
		<span v-if="news.length < 1">No results found for <strong>{{ searches }}</strong>, you can still take one look to older news! <a v-bind:href="searchlink">Click Here</a></span>
	</div>
</template>
<script>
import moment from 'moment';
export default {
	name: 'Search',
	props: {
		searches: String,
		news: {}
	},
	computed: {
		searchlink() {
			return "/cards.php?s=" + this.searches;
		}
	},
	methods: {
		dateinhum: function(dates) {
			return moment(dates).fromNow(true);
        }
	},
}
</script>