<template>
  <div id="app">
    <div class="uk-offcanvas-content">
      <div class="spacer"></div>
      <!--CARDS WRAPPER-->
      <section
        class="
          uk-section
          uk-section-small
          uk-section-default
          uk-padding-remove-bottom
        "
        data-uk-filter="target: .js-filter"
      >
        <div class="uk-container uk-container-expand uk-margin-large-bottom">
          <Search
            v-bind:searches="searches"
            v-bind:news="filteredNews"
          ></Search>
          <div v-if="filteredNews.length > 0">
            <span
              :uk-icon="'icon: settings'"
              class="uk-margin-top uk-margin-bottom"
              uk-toggle="target: #toggle-usage"
            ></span>
            Filter News
          </div>
          <ul class="uk-subnav uk-subnav-pill" id="toggle-usage" hidden>
            <li>News Sources :</li>
            <li class="uk-active" data-uk-filter-control>
              <a href="#">Show All</a>
            </li>
            <li data-uk-filter-control="[data-tags*='MoneyControl']">
              <a href="#">MoneyControl</a>
            </li>
            <li data-uk-filter-control="[data-tags*='The Hindu']">
              <a href="#">The Hindu</a>
            </li>
            <li data-uk-filter-control="[data-tags*='Reuters']">
              <a href="#">Reuters</a>
            </li>
            <li data-uk-filter-control="[data-tags*='EconomicTimes']">
              <a href="#">EconomicTimes</a>
            </li>
            <li data-uk-filter-control="[data-tags*='Business Line']">
              <a href="#">Business Line</a>
            </li>
            <li data-uk-filter-control="[data-tags*='Business Standard']">
              <a href="#">Business Standard</a>
            </li>
          </ul>

          <ul class="uk-subnav uk-subnav-pill" id="toggle-usage" hidden>
            <li>Categories :</li>
            <li class="uk-active" data-uk-filter-control>
              <a href="#">Show All</a>
            </li>
            <li data-uk-filter-control="[data-category*='market']">
              <a href="#">Market</a>
            </li>
            <li data-uk-filter-control="[data-category*='business']">
              <a href="#">Business</a>
            </li>
            <li data-uk-filter-control="[data-category*='sports']">
              <a href="#">Sports</a>
            </li>
            <li data-uk-filter-control="[data-category*='entertainment']">
              <a href="#">Entertainment</a>
            </li>
            <li data-uk-filter-control="[data-category*='global']">
              <a href="#">Global</a>
            </li>
            <li data-uk-filter-control="[data-category*='mutualfunds']">
              <a href="#">MutualFunds</a>
            </li>
            <li data-uk-filter-control="[data-category*='industry']">
              <a href="#">Industry</a>
            </li>
            <li data-uk-filter-control="[data-category*='commodity']">
              <a href="#">Commodity</a>
            </li>
            <li data-uk-filter-control="[data-category*='opinion']">
              <a href="#">Opinion</a>
            </li>
            <li data-uk-filter-control="[data-category*='result']">
              <a href="#">Result</a>
            </li>
            <li data-uk-filter-control="[data-category*='currency']">
              <a href="#">Currency</a>
            </li>
          </ul>

          <div
            class="
              uk-grid
              uk-grid-medium
              uk-child-width-1-2@s
              uk-child-width-1-3@m
              uk-child-width-1-4@l
              uk-child-width-1-5@xl
              uk-grid-match
              js-filter
            "
            data-uk-grid="masonry: true"
            data-uk-sortable="handle: .drag-icon"
          >
            <News
              v-for="news in filteredNews"
              v-bind:news="news"
              v-bind:key="news.id"
            ></News>
          </div>
          <ul class="uk-pagination">
            <li>
              <a href="#"
                ><span
                  class="uk-margin-small-right"
                  uk-pagination-previous
                ></span>
                Previous</a
              >
            </li>
            <li class="uk-margin-auto-left">
              <a href="#"
                >Next
                <span class="uk-margin-small-left" uk-pagination-next></span
              ></a>
            </li>
          </ul>
        </div>
      </section>
      <!--/CARDS WRAPPER-->
    </div>
  </div>
</template>

<script>
import axios from "axios";

import News from "./components/News.vue";
import Search from "./components/Search.vue";

// import clipboard from 'clipboard';
// new ClipboardJS('#link');
// url = new URL(window.location.href);
// curPage = url.searchParams.get("page");
// current = curPage  || '1';

export default {
  name: "app",
  components: {
    News,
    Search,
  },
  data() {
    return {
      newss: [],
      searches: "",
      page: 1, //this.$route.query.page
      title: "",
    };
  },
  created: function () {
    axios
      .get("https://kupura.com/api/v1/news?page=" + this.page)
      .then((response) => (this.newss = response.data.data));
  },
  computed: {
    filteredNews() {
      return this.newss;
      // return this.newss.filter(news => {
      // 	return news;
      // 	// return this.title = news.title.toLowerCase().includes(this.searches.toLowerCase());
      // 	// return news.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      // })
    },
  },
};
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  font-family: Quicksand;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#site-head {
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
#site-foot {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0.8rem;
}
.uk-section-xsmall {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.uk-logo img {
  width: 50px;
}
.spacer {
  height: 60px;
}
.uk-card {
  border-top: 2px solid;
  position: relative;
  transition: box-shadow 0.15s ease-out;
  border-radius: 3px;
}
.uk-card:hover {
  box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.045);
}
.uk-card .uk-inline {
  z-index: 99;
}
.uk-card .uk-card-footer {
  padding: 0.55rem 1rem;
}
.uk-card .uk-card-header {
  padding: 0.55rem 1rem;
}
.uk-card .cat-txt {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.01em;
}
.uk-subnav li a {
  border-radius: 3px;
}
.uk-subnav li.uk-active:nth-child(3) a {
  background-color: #8630f0;
}
.uk-subnav li.uk-active:nth-child(4) a {
  background-color: #f03a7f;
}
.uk-subnav li.uk-active:nth-child(5) a {
  background-color: #e84df0;
}

/*
market
business
sports
entertainment
global
currency
mutualfunds
industry
commodity
opinion
result
*/

.market .uk-card {
  border-top-color: #ab003c;
}
.market .uk-card .cat-txt {
  color: #ab003c;
}

.business .uk-card {
  border-top-color: #d68910;
}
.business .uk-card .cat-txt {
  color: #d68910;
}

.sports .uk-card {
  border-top-color: #ce93d8;
}
.sports .uk-card .cat-txt {
  color: #ce93d8;
}

.entertainment .uk-card {
  border-top-color: #cddc39;
}
.entertainment .uk-card .cat-txt {
  color: #cddc39;
}

.global .uk-card {
  border-top-color: #3478a6;
}
.global .uk-card .cat-txt {
  color: #3478a6;
}

.currency .uk-card {
  border-top-color: #b4988a;
}
.currency .uk-card .cat-txt {
  color: #b4988a;
}

.mutualfunds .uk-card {
  border-top-color: #62cdc6;
}
.mutualfunds .uk-card .cat-txt {
  color: #62cdc6;
}

.industry .uk-card {
  border-top-color: #f02e60;
}
.industry .uk-card .cat-txt {
  color: #f02e60;
}

.commodity .uk-card {
  border-top-color: #35a055;
}
.commodity .uk-card .cat-txt {
  color: #35a055;
}

.opinion .uk-card {
  border-top-color: #c0c0c0;
}
.opinion .uk-card .cat-txt {
  color: #c0c0c0;
}

.result .uk-card {
  border-top-color: #000;
}
.result .uk-card .cat-txt {
  color: #000;
}

/* .nature-card .uk-card {
border-top-color: #1E87F0;
}
.nature-card .uk-card .cat-txt {
    color: #1E87F0;
}
.music-card .uk-card {
    border-top-color: #8630F0;
}
.music-card .uk-card .cat-txt {
    color: #8630F0;
}
.design-card .uk-card {
    border-top-color: #E84DF0;
}
.design-card .uk-card .cat-txt {
    color: #E84DF0;
}
.photo-card .uk-card {
    border-top-color: #F03A7F;
}
.photo-card .uk-card .cat-txt {
    color: #F03A7F;
} */
.uk-card .uk-overlay-primary {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.7rem;
  text-transform: uppercase;
  opacity: 0.7;
}
img[data-src][src*="data:image"] {
  background: rgba(0, 0, 0, 0.1);
}
.search-fld {
  border-radius: 50px;
  padding: 0 1rem;
}
.uk-dropdown.drop-nav {
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.uk-dropdown.drop-comments {
  width: 370px;
}
.drop-nav::after {
  bottom: 100%;
  right: 4%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 6px;
  margin-left: -6px;
}
.uk-dropdown.uk-dropdown-right-center::after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 6px;
  margin-top: -6px;
}
.uk-dropdown.uk-dropdown-left-center::after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 6px;
  margin-top: -6px;
}
.message {
  font-size: 0.85rem;
  display: block;
  line-height: 1.3rem;
  padding: 0.5rem 0;
}
</style>
